import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Identity, Page } from '../../../models/client';
import { TokenDto } from '../../../models/server/DataTransferObject/Objects/Authentication';
import { RegisterDeviceByLocationDto } from '../../../models/server/DataTransferObject/Objects/Devices';
import { LocationListDto } from '../../../models/server/DataTransferObject/Objects/MasterData/Locations';
import { ContextCloak } from '../../../models/server/Enums';
import { HttpAnonymApiService } from '../http/http-api.service';
import { HttpLoginApiService } from '../http/http-login-api/http-login-api.service';
import {FilterDto} from '../../../models/server/DataTransferObject/Filters';

@Injectable({
  providedIn: 'root'
})
export class DeviceRegisterService {

  constructor(
    protected http: HttpLoginApiService,
    protected httpAnonym: HttpAnonymApiService
  ) { }

  public async registerByLocation(registrator: Identity, data: RegisterDeviceByLocationDto): Promise<HttpResponse<TokenDto>> {
    return this.http
      .addIdentity(registrator)
      .post<TokenDto>('login/RegisterDeviceByLocation', data)
      .toPromise();
  }

  public async getLocations(registrator: Identity, filter: string = null): Promise<LocationListDto[]> {

    return this.httpAnonym
      .addIdentity(registrator)
      .post<Page<LocationListDto>>(
          'location/filter?pageSize=999&sortField=tenantId&sortThenField=name&as=' + ContextCloak.User.toString(),
          !!filter ? ([
            <FilterDto> {
              property: 'name',
              value: filter
            },
            <FilterDto> {
              property: 'state',
              value:  '0' // LocationState.Open
            }
        ]) : [
          <FilterDto> {
            property: 'state',
            value:  '0' // LocationState.Open
          }
        ]
      )
      .pipe(map(x => x.body.items))
      .toPromise();
  }
}
